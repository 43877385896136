import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
          <p>
          Explore our Image Gallery showcasing the precision and innovation behind our Measurement Analytics and Parameter Monitoring solutions. Journey through a visual narrative of cutting-edge instruments, real-time data analytics, and the seamless integration of advanced sensors. Witness the power of accurate measurements and the technology shaping industries for a smarter, data-driven future.
          </p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                    <p>{`${d.title}`}</p>
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
