import React from "react";

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Meet the Team</h2>
          <p>
          Welcome to the Instrumentation Innovation Unit, where precision meets progress in the realm of Measurement Analytics. 
          Our team is dedicated to pushing the boundaries of instrumentation, combining cutting-edge technology with analytical expertise to revolutionize the way we measure and understand the world around us. From advanced sensors to data acquisition systems, we strive to develop and integrate instruments that not only meet the highest standards of accuracy but also empower industries to make informed decisions.
          </p>
        </div>
        <div id="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-6 col-sm-6 team">
                  <div className="thumbnail">
                    {" "}
                    <img src={d.img} alt="..." className="team-img" />
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
