import { Image } from "./image";
import React from "react";

export const Career = (props) => {
  return (
    <div id="career" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Current Opportunity</h2>
        </div>
        <div className="row">
                <h1>1.Sales and Marketing Representative</h1>

                <h3>Location: Mumbai, Nagpur</h3>

                <p><strong>Job Type:</strong> [Full-time, Part-time, Contract, etc.]</p>

                <h4>Responsibilities:</h4>
                <ul>
                    <li>Develop and implement effective sales and marketing strategies to achieve and exceed sales targets</li>
                    <li>Identify and pursue new business opportunities through market research and analysis</li>
                    <li>Build and maintain strong relationships with clients, understanding their needs and providing tailored solutions</li>
                    <li>Conduct product presentations and demonstrations to potential clients</li>
                    <li>Collaborate with cross-functional teams to ensure the successful execution of marketing campaigns</li>
                    <li>Stay informed about industry trends and competitors to enhance the company's competitive position</li>
                </ul>

                <h4>Qualifications: MBA, Diploma In Instrumentation</h4>

        </div>
        <div className="row">
                <h1>2.Accountant Executive</h1>

                <h3>Location: Mumbai, Nagpur</h3>

                <p><strong>Job Type:</strong> [Full-time, Part-time, Contract, etc.]</p>

                <h4>Responsibilities:</h4>
                <ul>
                    <li>Accurately maintain and update financial records, including ledgers, journals, and accounting software.</li>
                    <li>Generate and analyze financial reports, such as balance sheets, income statements, and cash flow statements </li>
                    <li>ensuring accuracy and compliance with accounting standards.</li>
                    <li>Conduct financial analysis to identify trends, variances, and opportunities for improvement.</li>
                    <li>Ensure adherence to financial regulations and company policies.</li>
                </ul>

                <h4>Qualifications: B.com,M.com,DTL,GDCA</h4>
        </div>
        <div className="row">
                <h1>How to Apply:</h1>
                <a href="mailto:vc97analyticos@gmail.com">vc97analyticos@gmail.com</a> ,  
                <a href="mailto:aj91analyticos@gmail.com">aj91analyticos@gmail.com</a>
        </div>
      </div>
    </div>
  );
};
